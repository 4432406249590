import logo200Image from 'assets/img/logo/logo-apps.png';
import React from 'react';
import { Redirect } from 'react-router-dom';
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import { Card, Col, Row, Button, FormGroup, Label } from 'reactstrap';
import { connect } from 'react-redux';
import { login } from "../actions/auth";
import CheckButton from "react-validation/build/button";
import { USER_ROLES } from '../utils/user-role';
import { history } from '../history';  

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

class AuthPage extends React.Component {
  constructor(props) {
    super(props);
    this.handleLogin = this.handleLogin.bind(this);
    this.onChangeUsername = this.onChangeUsername.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);

    this.state = {
      username: "",
      password: "",
      loading: false,
      showLogo: true
    };
  }

  changeAuthState = authState => event => {
    event.preventDefault();
    this.props.onChangeAuthState(authState);
  };

  onChangeUsername(e) {
    this.setState({
      username: e.target.value,
    });
  }

  onChangePassword(e) {
    this.setState({
      password: e.target.value,
    });
  }


  handleLogin = (e) => {
    e.preventDefault();

    this.setState({
      loading: true,
    });

    this.form.validateAll();

    const { dispatch, user } = this.props;

    if (this.checkBtn.context._errors.length === 0) {
      dispatch(login(this.state.username, this.state.password))
        .then(() => {          
          const role = user.roles.toString();
          if(role === USER_ROLES.ADMIN) {
            history.push("/adm_dashboard");
          }

          if(role === USER_ROLES.SEKOLAH) {
            history.push("/sekolah_dashboard");
          }

          if(role === USER_ROLES.SISWA) {
            history.push("/userhome");
          }

          window.location.reload();
            
        })
        .catch(() => {
          this.setState({
            loading: false
          });
        });
    } else {
      this.setState({
        loading: false,
      });
    }
  }

  render() {
    const {isLoggedIn, message, user } = this.props;
    if (isLoggedIn) {
      const role = user.roles.toString();
      if(role === USER_ROLES.ADMIN) {
        return <Redirect exact={true} to="/adm_dashboard" />;       
      }
      
      if(role === USER_ROLES.SEKOLAH) {
        return <Redirect exact={true} to="/sekolah_dashboard" />;  
      }

      if(role === USER_ROLES.SISWA) {
        return <Redirect exact={true} to="/userhome" />;  
      }
    }

    return (
      <Row
        style={{
          height: '100vh',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <Col md={6} lg={4}>
          <Card body>
            <Form  onSubmit={this.handleLogin}
              ref={(c) => {
                this.form = c;
              }}>
                {this.state.showLogo && (
                  <div className="row text-center pb-4">
                    <img
                      src={logo200Image}
                      className="rounded m-auto"
                      alt="logo" style={{width: 320}}
                    />
                  </div>
                )}
                <FormGroup>
                  <Label for="username">Username</Label>
                  <Input
                        type="text"
                        className="form-control"
                        name="username"
                        placeholder= 'your username'
                        value={this.state.username}
                        onChange={this.onChangeUsername}
                        validations={[required]}
                      />
                </FormGroup>
                <FormGroup>
                  <Label for="password">Password</Label>
                  <Input
                        type="password"
                        className="form-control"
                        name="password"
                        placeholder= 'your password'
                        value={this.state.password}
                        onChange={this.onChangePassword}
                        validations={[required]}
                      />
                </FormGroup>
                <hr />
                <Button
                  size="lg"
                  type="submit"
                  className="btn-login bg-gradient-theme-right border-0"
                  disabled={this.state.loading}
                  block>            
                    
                    {this.state.loading ? (
                      <span className="spinner-border spinner-border"></span>
                    ) : <span>Login</span>}
                    
                </Button>

                {message && (
                  <div className="form-group">
                    <div className="alert alert-danger" role="alert">
                      {message}
                    </div>
                  </div>
                )}
                <CheckButton
                  style={{ display: "none" }}
                  ref={(c) => {
                    this.checkBtn = c;
                  }}
                />

                
            </Form>
          </Card>
        </Col>
      </Row>
    );
  }
}


function mapStateToProps(state) {
  const { isLoggedIn, user } = state.auth;
  const { message } = state.message;
  return {
    isLoggedIn,
    user,
    message
  };
}

export default connect(mapStateToProps)(AuthPage);
