
import React from 'react';
import { Spinner } from 'reactstrap';
import Typography from '@mui/material/Typography';
const SpinnerSubmit = () => {
  return (    
    <Typography variant="caption" display="block" gutterBottom sx={{ color: '#6d6d6d'}}>
       Menyimpan jawaban, mohon tunggu &nbsp;
     <Spinner color="danger" size="sm" type="grow" />
     <Spinner color="warning" size="sm" type="grow" />
     <Spinner color="info" size="sm" type="grow" />
    </Typography>
  );
};

export default SpinnerSubmit;
