import React from 'react';
import { history } from '../history';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

class UnAuthorized extends React.Component {

    goHome() {
        history.push({ pathname: '/' })
    }

    render() {
        const { isLoggedIn } = this.props;
        if(!isLoggedIn) {
            return <Redirect exact={true} to="/" />;
        }
        return (
            <div className="body404">
                <div className="align-self-center">
                    <h1 style={{ fontSize: '12em', margin: '35px 0px', fontWeight: 'bold' }}>404</h1>
                    <h2 style={{ fontWeight: 'bold' }}>UH OH! You're lost.</h2>
                    <p>The page you are looking for does not exist.
                    How you got here is a mystery. 
                    </p>
                    <p>But you can click the button below
                    to go back to the homepage.</p>
                    <button className="btn btn404 green" onClick={this.goHome}>HOME</button>
                </div>
            </div>
        );
    };
}

function mapStateToProps(state) {
    const { isLoggedIn, user } = state.auth;
    return {
      isLoggedIn,
      user
    };
  }
  
export default connect(mapStateToProps)(UnAuthorized);
