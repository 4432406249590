import axios from 'axios';
import { API_REACTIVE } from './config';
import Swal from 'sweetalert2';
import authHeader from "../services/auth-header";
import { history } from '../history';

const instance = axios.create({
    baseURL: API_REACTIVE.HOST,
    headers: authHeader() 
});

instance.defaults.withCredentials = false;
instance.interceptors.response.use(response => { 
    return response;
}, function (error) {
    if(error.response.status === 401) {
        Swal.fire({
            title: 'Oops...',
            text: 'Sesi login anda telah berakhir',
            icon: 'warning',
            allowOutsideClick: false,
            allowEscapeKey: false,
            timer: 1800
        }).then(() => {
            localStorage.clear();
            window.location.href="/";
        }) 
    }
    else if(error.response.data !== undefined && error.response.data !== ""){
        Swal.fire({
            title: 'Oops...',
            text: error.response.data.message,
            icon: 'error',
            allowOutsideClick: false,
            allowEscapeKey: false,
            timer: 1800
        }).then(() => {
            history.push({pathname: '/'})
        })
    }
    else {
        Swal.fire({
            title: 'Oops...',
            text: error.message,
            icon: 'error',
            allowOutsideClick: false,
            allowEscapeKey: false
        })
    }
    return Promise.reject(error);    
});

export default instance;