import axios from "axios";
import { API_REACTIVE } from '../apis/config';
import { USER_ROLES } from "../utils/user-role";
const API_URL = "https://core.baggrek.com/api/auth/"; 

class AuthService {
  async login(username, password) {
    const response = await axios.post(API_URL + "login", { username, password });
    if(response) {
      if (response.data.resCode === 'E000') {        
        var user = response.data.result;
        if(user.roles.includes(USER_ROLES.SEKOLAH)) {   
          await axios.get(API_REACTIVE.HOST +  API_REACTIVE.SEKOLAH_FIND_BY_ID + user.referenceId, 
            {
              headers: { Authorization: `Bearer ${user.accessToken}` }
            }).then( res => {
              if(res.data.resCode === API_REACTIVE.RESPONSE_CODE_SUCCESS) {
                user = {...user, jenjang : res.jenjang, nama: res.nama};
              
                localStorage.setItem("user", JSON.stringify(user));
                return user;
              }
            }).catch(error => { 
              return Promise.reject(error); 
            });  
        } 
        if(user.roles.includes(USER_ROLES.ADMIN)) {  
          localStorage.setItem("user", JSON.stringify(user));
          return user;
        }
        if(user.roles.includes(USER_ROLES.SISWA)) {  
          localStorage.setItem("user", JSON.stringify(user));
          return user;
        }
      }
    }      
  }

  logout() {
    
    localStorage.clear()
  }

  register(username, email, password) {
    return axios.post(API_URL + "signup", {
      username,
      email,
      password,
    });
  }
}

export default new AuthService();
