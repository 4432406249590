import React from 'react'
import { Col } from 'reactstrap';

class Loader extends React.Component{
    render(){
        return(
            <Col className="text-center mt-5 mb-5" xl={12} lg={12} md={12} xs={12}>
                <img src="/img/loader/Pulse-1s-200px.svg" style={{height: '75px'}} className="text-center" alt="loaderImage" />
            </Col>
        )
    }
}

export default Loader;