
import { EmptyLayout, LayoutRoute, MainLayout } from 'components/Layout';
import PageSpinner from 'components/PageSpinner';
import AuthPage from 'pages/AuthPage';
import SiswaTestPage from 'pages/siswa/SiswaTestPage';
import React from 'react';
import componentQueries from 'react-component-queries';
import { Router, Route, Switch} from 'react-router-dom';
import './styles/reduction.scss';
import { history } from './history';
import UnAuthorized from 'pages/UnAuthorized';

// admin sidebar
const AdminDashboard = React.lazy(() => import('pages/admin/admin-dashboard'));
const AdminSekolahPage = React.lazy(() => import('pages/admin/admin-sekolahPage'));
const AdminAkunSiswaPage = React.lazy(() => import('pages/admin/admin-akunSiswaPage'));
const AdminAkunSiswaDetailPage = React.lazy(() => import('pages/admin/admin-AkunSiswaDetailPage'));
const AdminReportPage = React.lazy(() => import('pages/admin/admin-reportPage'));
const AdminSoalPage = React.lazy(() => import('pages/admin/admin-soalPage'));
const AdminMasterMateriKategoriPage = React.lazy(() => import('pages/admin/admin-masterMateriKategoriPage'));
const AdminMasterMateriPage = React.lazy(() => import('pages/admin/admin-masterMateriPage'));
const AdminMasterKelasPage = React.lazy(() => import('pages/admin/admin-masterKelasPage'));
const AdminSoalPageView = React.lazy(() => import('pages/admin/admin-soalPageView'));
const AdminInstruksiPengerjaanPage = React.lazy(() => import('pages/admin/admin-instruksiPengerjaanPage'));
const AdminResetPengerjaanSiswa = React.lazy(() => import('pages/admin/admin-resetPengerjaanSiswa'));
const AdminSoalIstMePop = React.lazy(() => import('components/admin/SoalIstMePop.js'));
const AdminMapelPage = React.lazy(() => import('pages/admin/admin-mapelPage'));
const AdminConfigPage = React.lazy(() => import('pages/admin/admin-configPage'));

//sekolah sidebar
const SekolahDashboardPage = React.lazy(() => import('pages/sekolah/sekolah-dashboardPage'));
const SekolahJadwalPage = React.lazy(() => import('pages/sekolah/sekolah-jadwalPage'));
const SekolahManjAkunUserPage = React.lazy(() => import('pages/sekolah/sekolah-manjAkunUserPage'));
const SekolahTrackingPengerjaanPage = React.lazy(() => import('pages/sekolah/sekolah-trackingPengerjaanPage'));
const SekolahTrackingPengerjaanDetail = React.lazy(() => import('pages/sekolah/sekolah-trackingPengerjaanDetail'));
const SekolahReportPage = React.lazy(() => import('pages/sekolah/sekolah-reportPage'));

// siswa
const SiswaDashboardPage = React.lazy(() => import('pages/siswa/siswa-dashboardPage'));
const SiswaPreTestPage = React.lazy(() => import('pages/siswa/siswa-preTestPage'));
const SiswaReportPage = React.lazy(() => import('pages/siswa/siswa-reportPage'));
// const SiswaTestPage = React.lazy(() => import('pages/siswa/siswa-testPage'));

const RoleRoute = ({ allowedRoles, ...props }) => {
  const user = JSON.parse(localStorage.getItem('user'));
  if(user === null) 
    window.location.href = "/" ;

  const userRoles = user.roles; 
  return allowedRoles.find((role) => userRoles.includes(role)) ? (
    <Route {...props} />
  ) : (
    <></>
  ) 
};

class App extends React.Component {
  render() {
    return (
      <Router history={history}>
        {/* <GAListener> */}
          <Switch>
            <LayoutRoute
              exact
              path="/"
              layout={EmptyLayout}
              component={props => (
                <AuthPage {...props} />
              )}
            />

            <LayoutRoute
              exact
              path="/unauthorized"
              layout={EmptyLayout}
              component={props => (
                <UnAuthorized {...props} />
              )}
            />

            <LayoutRoute              
              exact
              path="/siswa/test"
              layout={EmptyLayout}
              component={props => (
                <SiswaTestPage {...props} breakpoint={this.props.breakpoint} />
              )}
            />            

            <MainLayout breakpoint={this.props.breakpoint}>
              <React.Suspense fallback={<PageSpinner />}>             
                {/*admin*/}
                
                {/* <Route exact path="/adm_dashboard" component={AdminDashboard} />
                <Route exact path="/sekolah" component={AdminSekolahPage} />
                <Route exact path="/siswa" component={AdminAkunSiswaPage} />
                <Route exact path="/siswa_detail" component={AdminAkunSiswaDetailPage} />
                <Route exact path="/report" component={AdminReportPage} />
                <Route exact path="/soal" component={AdminSoalPage} />
                <Route exact path="/soal/addPopMe" component={AdminSoalIstMePop} />
                <Route path="/soal/view" component={AdminSoalPageView} />
                <Route path="/materi/instruksiPengerjaan" component={AdminInstruksiPengerjaanPage} />
                <Route exact path="/kategori_materi" component={AdminMasterMateriKategoriPage} />
                <Route exact path="/materi" component={AdminMasterMateriPage} />
                <Route exact path="/kelas" component={AdminMasterKelasPage} />  
                <Route exact path="/siswa/resetPengerjaan/:sekolahId" component={AdminResetPengerjaanSiswa} /> */}

                <RoleRoute exact path="/adm_dashboard" component={AdminDashboard} allowedRoles={['ROLE_ADMIN']}/>
                <RoleRoute exact path="/sekolah" component={AdminSekolahPage} allowedRoles={['ROLE_ADMIN']}/>
                <RoleRoute exact path="/siswa" component={AdminAkunSiswaPage} allowedRoles={['ROLE_ADMIN']}/>
                <RoleRoute exact path="/siswa_detail" component={AdminAkunSiswaDetailPage} allowedRoles={['ROLE_ADMIN']}/>
                <RoleRoute exact path="/report" component={AdminReportPage} allowedRoles={['ROLE_ADMIN']}/>
                <RoleRoute exact path="/soal" component={AdminSoalPage} allowedRoles={['ROLE_ADMIN']}/>
                <RoleRoute exact path="/soal/addPopMe" component={AdminSoalIstMePop} allowedRoles={['ROLE_ADMIN']}/>
                <RoleRoute path="/soal/view" component={AdminSoalPageView} allowedRoles={['ROLE_ADMIN']}/>
                <RoleRoute path="/materi/instruksiPengerjaan" component={AdminInstruksiPengerjaanPage} allowedRoles={['ROLE_ADMIN']}/>
                <RoleRoute exact path="/kategori_materi" component={AdminMasterMateriKategoriPage} allowedRoles={['ROLE_ADMIN']}/>
                <RoleRoute exact path="/materi" component={AdminMasterMateriPage} allowedRoles={['ROLE_ADMIN']}/>
                <RoleRoute exact path="/kelas" component={AdminMasterKelasPage} allowedRoles={['ROLE_ADMIN']}/>  
                <RoleRoute exact path="/siswa/resetPengerjaan/:sekolahId" component={AdminResetPengerjaanSiswa} allowedRoles={['ROLE_ADMIN']}/>
                <RoleRoute exact path="/mapel" component={AdminMapelPage} allowedRoles={['ROLE_ADMIN']}/> 
                <RoleRoute exact path="/config" component={AdminConfigPage} allowedRoles={['ROLE_ADMIN']}/> 
                
                {/*sekolah*/}     
                {/* <Route exact path="/sekolah_dashboard" component={SekolahDashboardPage} />
                <Route exact path="/jadwal" component={SekolahJadwalPage} />
                <Route exact path="/userakun" component={SekolahManjAkunUserPage} />
                <Route exact path="/trackingPengerjaan" component={SekolahTrackingPengerjaanPage} /> */}

                <RoleRoute exact path="/sekolah_dashboard" component={SekolahDashboardPage} allowedRoles={['ROLE_SEKOLAH']}/>
                <RoleRoute exact path="/jadwal" component={SekolahJadwalPage} allowedRoles={['ROLE_SEKOLAH']}/>
                <RoleRoute exact path="/jadwalSakolah/:jenjang/:sekolahId" component={SekolahJadwalPage} allowedRoles={['ROLE_ADMIN']}/>
                <RoleRoute exact path="/userakun" component={SekolahManjAkunUserPage} allowedRoles={['ROLE_SEKOLAH']}/>
                <RoleRoute exact path="/trackingPengerjaan" component={SekolahTrackingPengerjaanPage} allowedRoles={['ROLE_SEKOLAH']}/>
                <RoleRoute exact path="/tracking/detail" component={SekolahTrackingPengerjaanDetail} allowedRoles={['ROLE_SEKOLAH','ROLE_ADMIN']}/>
                <RoleRoute exact path="/report_sekolah" component={SekolahReportPage} allowedRoles={['ROLE_SEKOLAH']}/>

                {/*siswa*/}
                {/* <Route exact path="/userhome" component={SiswaDashboardPage} />
                <Route exact path="/siswa/pre-test" component={SiswaPreTestPage} /> 
                <Route exact path="/siswa/report" component={SiswaReportPage} />  */}
                
                <RoleRoute exact path="/siswa/report/:sekolahId/:siswaId" component={SiswaReportPage} allowedRoles={['ROLE_USER','ROLE_ADMIN', 'ROLE_SEKOLAH']}/>
                <RoleRoute exact path="/userhome" component={SiswaDashboardPage} allowedRoles={['ROLE_USER']}/>
                <RoleRoute exact path="/siswa/pre-test" component={SiswaPreTestPage} allowedRoles={['ROLE_USER']}/>
                              
              </React.Suspense>
            </MainLayout>
            
          </Switch>
        {/* </GAListener> */}
      </Router>
    );
  }
}

const query = ({ width }) => {
  if (width < 575) {
    return { breakpoint: 'xs' };
  }

  if (576 < width && width < 767) {
    return { breakpoint: 'sm' };
  }

  if (768 < width && width < 991) {
    return { breakpoint: 'md' };
  }

  if (992 < width && width < 1199) {
    return { breakpoint: 'lg' };
  }

  if (width > 1200) {
    return { breakpoint: 'xl' };
  }

  return { breakpoint: 'xs' };
};

export default componentQueries(query)(App);
